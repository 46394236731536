@import "var";

.mat-table {
  width: 100%;

  .mat-sort-header-container {
    flex: 1 1 100%;
    align-items: flex-start;
  }

  .mat-sort-header-content {
    flex-direction: column;
    flex: 1 1 100%;
    align-items: flex-start;

    .header-content {
      font-size: 13px;
      line-height: 15.75px;
      font-weight: 500;
    }
  }

  .mat-header-row .mat-header-cell .mat-sort-header-button {
    text-transform: uppercase;
  }

  .mat-cell.image-cell, .mat-header-cell.image-cell {
    flex: 0;
    width: 35px + $spacing;

    img {
      border-radius: 50%;
      height: 35px;
      width: 35px;
      margin-right: $spacing;
      vertical-align: middle;
    }
  }

  .mat-cell, .mat-header-cell {
    white-space: nowrap;
    padding: 10px 8px;
  }

  .mat-header-cell {
    vertical-align: top;
  }

  .mat-cell.actions-cell, .mat-header-cell.actions-cell {
    flex: 0;
    width: 40px;
    overflow: visible;
  }

  mtx-grid-cell .mat-icon-button {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .paginator {
    display: block;
  }

  .mat-column-is_active,
  .mat-column-is_visible {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }

  .mat-column-actions {
    white-space: nowrap;
    text-align: right;
    flex: 0 1 80px;
    align-items: center;
    justify-content: end;

    &.b1 {
      width: 60px;
      min-width: 60px;
      max-width: 60px;
    }

    &.b2 {
      width: 105px;
      min-width: 105px;
      max-width: 105px;
    }

    &.b3 {
      width: 145px;
      min-width: 145px;
      max-width: 145px;
    }

    &.b4 {
      width: 175px;
      min-width: 175px;
      max-width: 175px;
    }
  }

  .mat-icon {
    vertical-align: middle;
  }
}

.mat-header-row, .paginator, .table-paginator {
  background: $theme-app-bar;
}

.mat-row:hover {
  background: #ebebeb;
}

.mat-cell.actions-cell, .mat-header-cell.actions-cell {
  button {
    color: $theme-icon;
  }
}

.mat-cell.image-cell, .mat-header-cell.image-cell {
  img {
    background: $theme-app-bar;
  }
}

.table-paginator {
  .mat-form-field {
    margin-top: 0;

    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-flex {
        padding-top: .45em;

        .mat-form-field-infix {
          width: auto;
          border-top: 0;

          .mat-select-arrow-wrapper {
            transform: none;
          }
        }
      }
    }

    .mat-form-field-underline {
      bottom: 0;
    }
  }
}

tr.deleted,
tr.deleted td,
td.deleted {
  background-color: #fff3f3;
  color: rgba(0, 0, 0, 0.4) !important;
  font-style: italic;
}
