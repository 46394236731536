@import "@angular/material/theming";
@import "./var-helpers";

// Utilities
@import "../partials/utilities";
// Mat Table
@import "../partials/table";
// Mat changes
@import "../partials/mat";

// Mat color changes
$mat-background-color-disabled: rgba(0, 0, 0, .1) !default;
$mat-text-color-disabled: rgba(0, 0, 0, .7) !default;
$mat-text-background-color: rgba(0, 0, 0, .04) !default;
$mat-text-color: rgba(0, 0, 0, .87) !default;
$mat-text-background-color-inverted: rgba(0, 0, 0, .87) !default;
$mat-text-color-inverted: rgba(255, 255, 255, 1) !default;
$mat-text-label-color: rgba(0, 0, 0, .6) !default;
$mat-divider-color: rgba(0, 0, 0, .1) !default;
$table-deleted-row-background-color: rgb(255, 243, 243) !default;
$table-deleted-row-text-color: rgba(0, 0, 0, .4) !default;
$table-disabled-row-background-color: rgba(200, 200, 200, .1) !default;
$table-disabled-row-text-color: rgba(0, 0, 0, .4) !default;
$table-divider-color: rgba(0, 0, 0, .1) !default;

$mat-button-default-text-color: #333333 !default;
$mat-button-default-background-color: #cccccc !default;
$mat-button-black-text-color: #ffffff !default;
$mat-button-black-background-color: #333333 !default;

// Card
@import "../../shared/card/card.component.theme";

// Backdrop
@import "../../shared/backdrop/backdrop.directive.theme";

// Widgets
@import "../../shared/alert-box/alert-box.component.theme";

$leaflet-default-marker-color: rgba(0, 0, 0, .8) !default;
$leaflet-marker-shadow: rgba(0, 0, 0, .4) !default;
$leaflet-tools-shadow: rgba(0, 0, 0, .2) !default;
@import "../partials/leaflet.theme";

@import "../partials/mat.theme";

