.mat-button.mat-primary.mat-button-disabled,
.mat-button.mat-accent.mat-button-disabled,
.mat-button.mat-warn.mat-button-disabled,
.mat-button.mat-button-disabled.mat-button-disabled,
.mat-icon-button.mat-primary.mat-button-disabled,
.mat-icon-button.mat-accent.mat-button-disabled,
.mat-icon-button.mat-warn.mat-button-disabled,
.mat-icon-button.mat-button-disabled.mat-button-disabled,
.mat-stroked-button.mat-primary.mat-button-disabled,
.mat-stroked-button.mat-accent.mat-button-disabled,
.mat-stroked-button.mat-warn.mat-button-disabled,
.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.8) !important;
}

.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.8) !important;
}

.mat-icon {
  min-width: 24px;
  min-height: 24px;
}

.mat-sort-header-arrow {
  margin: 6px !important;
}

.mat-header-cell {
  align-items: start;
}

.mat-header-cell-inner {
  flex-direction: column;
}

.mat-table .mat-sort-header-container {
  border: none !important;
}

.mat-input-element[type=color] {
  margin: -3px 0 -7px;
}

.mat-form-field {
  .mat-spinner {
    position: absolute;
    top: 0;
    right: 0;
  }

  textarea.mat-input-element {
    line-height: 1.5;
  }
}

.mat-row-no-data {
  display: flex;

  .mat-icon {
    margin-right: 8px;
    vertical-align: middle;
  }

  div {
    flex: 1;
    line-height: 48px;
    text-align: center;
    font-style: italic;
    color: #aaa;
    display: inline-block;
  }
}

.mat-form-field-infix {
  width: auto !important;
}

.mat-form-field-underline {
  position: static !important;
  bottom: auto;
}

.mat-form-field-subscript-wrapper {
  margin-top: 0.3em;
  position: static !important;
}

.mat-raised-button {
  line-height: 35px;

  .mat-icon {
    min-width: 21px;
    min-height: 21px;
    height: 21px;
    width: 21px;
    font-size: 21px;
    margin: -2px 3px 0 -3px;

    &.last {
      margin: -2px -6px 0 3px;
    }
  }
}

.mat-tooltip {
  font-size: 13px;
  margin: 0 !important;
}

.mat-checkbox {
  margin-bottom: 16px;
}

.mat-checkbox-group {
  margin-bottom: 16px;

  .mat-checkbox {
    margin-bottom: 0;
    display: block;
  }
}

fury-page-header-buttons .mat-checkbox,
mat-expansion-panel .mat-checkbox,
.mat-table .mat-checkbox {
  margin-bottom: 0;
}

.mat-checkbox-layout {
  max-width: 100%;

  .mat-checkbox-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.mat-tab-label .mat-tab-label-content {
  font-weight: bold;
}

.mat-tab-body {
  animation: fade-out 0.25s;
  opacity: 0;
}

.mat-tab-body.mat-tab-body-active {
  animation: fade-in 0.25s;
  opacity: 1;
}

.mat-dialog-container {
  height: auto !important;
  min-width: 80vw;
  max-width: 80vw;
  position: relative;
}

@media(min-width: 1199px) {
  .mat-dialog-container {
    min-width: 50vw;
    max-width: 50vw;
  }

  .wide {
    max-width: 90vw !important;

    .mat-dialog-container {
      min-width: 90vw;
      max-width: 90vw;
    }
  }
}

.mat-form-field-required-marker {
  font-weight: bold;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 4px;
}

.mat-option-multiline {
  line-height: initial !important;
}

.mat-accordion {
  margin-bottom: 16px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.mat-button.mat-green,
.mat-icon-button.mat-green,
.mat-stroked-button.mat-green {
  background: #9ac43b;
  color: #fff;
}

.mat-standard-chip {
  min-height: 24px !important;
  font-size: 12px;
}

.mat-tab-label-content {
  .mat-icon {
    font-size: 20px;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    margin-left: 8px;
    margin-top: -2px;
    color: #c00;
  }
}

mat-select-trigger {
  mat-icon {
    margin-top: -3px;
    margin-right: 2px;
    vertical-align: middle;
  }
}

.mat-optgroup .mat-option:not(.mat-option-multiple) {
  padding-left: 16px !important;
}

.mat-icon-button .mat-spinner {
  display: inline-block;
}
