@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker hue.
// $theme-primary: mat-palette($mat-teal);
// $theme-accent:  mat-palette($mat-light-blue, 500, 200, 700);

$theme-primary-hue: 700;
$theme-accent-hue: 500;
$theme-warn-hue: 500;

$theme-primary: mat.define-palette(mat.$blue-palette, $theme-primary-hue);
$theme-primary-dark: mat.define-palette(mat.$grey-palette, 100);
$theme-accent: mat.define-palette(mat.$teal-palette, $theme-accent-hue);
$theme-warn: mat.define-palette(mat.$red-palette, $theme-warn-hue);

$theme: mat.define-light-theme((
        color: (
                primary: $theme-primary,
                accent: $theme-accent,
                warn: $theme-warn
        )
));
$dark-theme: mat.define-dark-theme((
        color: (
                primary: $theme-primary-dark,
                accent: $theme-accent,
                warn: $theme-warn
        )
));
@include mat.all-component-themes($theme);

@include mtx.all-component-themes($theme);

.fury-default {
  @import "../@fury/styles/themes/default";

  background: #ffffff;
  color: $theme-text;
}

.fury-dark {
  @include mat.all-component-colors($dark-theme);
  @include mtx.all-component-colors($dark-theme);
  $theme: $dark-theme;

  @import "../@fury/styles/themes/dark";

  background: $theme-background;
  color: $theme-text;
}
