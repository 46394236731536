@use '@angular/material' as mat;

blockquote {
  margin: 1em 0 1.5em;
  padding: 0 1.5em;
  border-left: 3px solid $theme-color-primary;
  font-style: italic;
}

.text-center {
  text-align: center;
}

.card {
  background: $theme-card;
}

.background {
  background: $theme-background;
}

.pointer {
  cursor: pointer;
  outline: none;
}

@each $property in (background, color, border-color, fill) {
  @each $direction in (primary, accent, warn, primary-contrast, accent-contrast, warn-contrast) {
    .#{$property}-#{$direction} {
      @if $direction == primary {
        #{$property}: $theme-color-primary;
      }
      @if $direction == primary-contrast {
        #{$property}: $theme-color-primary-contrast;
      }
      @if $direction == accent {
        #{$property}: $theme-color-accent;
      }
      @if $direction == accent-contrast {
        #{$property}: $theme-color-accent-contrast;
      }
      @if $direction == warn {
        #{$property}: $theme-color-warn;
      }
      @if $direction == warn-contrast {
        #{$property}: $theme-color-warn-contrast;
      }
    }
  }
}

@each $property in (border) {
  @each $direction in ('', -left, -right, -top, -bottom) {
    .#{$property}#{$direction} {
      #{$property}#{$direction}: 1px solid $theme-divider;
    }
  }
}

@each $property in (margin, padding) {
  @each $direction in ('', -left, -right, -top, -bottom, -vertical, -horizontal) {
    @each $prefix, $value in $paddings {

      @if ($direction == -vertical) {
        .#{$property}#{$direction}#{$prefix} {
          #{$property}-top: $value;
          #{$property}-bottom: $value;
        }
      } @else if ($direction == -horizontal) {
        .#{$property}#{$direction}#{$prefix} {
          #{$property}-left: $value;
          #{$property}-right: $value;
        }
      } @else {
        .#{$property}#{$direction}#{$prefix} {
          #{$property}#{$direction}: $value;
        }
      }
    }
  }
}

@each $prefix, $value in $icon-sizes {
  .mat-icon.icon-size#{$prefix} {
    height: $value;
    width: $value;
    font-size: $value;
    line-height: $value;
  }
}
