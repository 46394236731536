.mat-form-field-appearance-fill.mat-form-field-disabled {
  .mat-form-field-flex {
    background-color: $mat-background-color-disabled;
  }

  .mat-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: $mat-text-color-disabled;
  }

  .mat-form-field-label {
    color: $mat-text-color-disabled;
  }
}

.mat-select-disabled .mat-select-value {
  color: $mat-text-color-disabled;
}

.mat-input-element {
  caret-color: $mat-text-color;
}

.mat-form-field-required-marker {
  color: $theme-color-warn;
}

.mat-focused .mat-form-field-required-marker {
  color: $theme-color-warn;
}

.fake-mat-form-field {
  mat-error {
    color: $theme-color-warn;
  }
}

.fake-mat-form-field {
  .fake-mat-form-field-content {
    background: $mat-text-background-color;
    color: $theme-text;

    &:after {
      border-bottom-color: $mat-text-color;
    }

    &:hover:after {
      border-bottom-color: $mat-text-color;
    }
  }

  mat-label,
  mat-error {
    color: $mat-text-label-color;
  }

  &.quill {
    background: transparent;

    .fake-mat-form-field-content {
      background: transparent;
    }

    .quill-container {
      background: transparent;
    }
  }
}

.mat-dialog-content {
  h2 {
    border-bottom-color: $mat-divider-color;
  }

  .entry {
    & + .entry {
      border-top-color: $mat-divider-color;
    }
  }
}

.mat-row.deleted,
.mat-row.deleted .mat-cell,
tr.deleted,
tr.deleted td,
td.deleted {
  background-color: $table-deleted-row-background-color;
  color: $table-deleted-row-text-color;
}

.mat-row.disabled,
.mat-row.disabled .mat-cell,
tr.disabled,
tr.disabled td,
td.disabled {
  background-color: $table-disabled-row-background-color;
  color: $table-disabled-row-text-color;
}

.mat-row.anonymized,
.mat-row.anonymized .mat-cell,
tr.anonymized,
tr.anonymized td,
td.anonymized {
  background-color: $table-disabled-row-background-color;
  color: $table-disabled-row-text-color;
}

.mtx-calendar-header {
  background: $mat-text-background-color;
  color: $mat-text-color;
}

.mtx-calendar-body-cell-content.mtx-calendar-body-selected,
.mtx-clock-cell.mtx-clock-cell-selected {
  background: $mat-text-background-color-inverted;
  color: $mat-text-color-inverted;
}

table.mat-table .mat-row.table-expand-row {
  &.expanded:hover,
  &.expanded:active {
    background: $theme-card;
  }
}

.mat-no-data-row {
  &:hover {
    background: transparent;
  }

  .mat-cell {
    background: $mat-text-background-color;
  }
}

.mat-checkbox-indeterminate.mat-dark-grey .mat-checkbox-background,
.mat-checkbox-checked.mat-dark-grey .mat-checkbox-background {
  background: $theme-color-primary;
}

.mat-option.selected-companies {
  background: $mat-text-background-color;

  .mat-option-text {
    .mat-icon {
      color: $mat-text-color;
    }
  }
}

.mat-option.selected-companies + .mat-option:not(.selected-companies) {
  border-top-color: $mat-text-background-color;
}

.mat-primary.companies-selector .mat-option.selected-companies.mat-selected:not(.mat-option-disabled),
.mat-primary.companies-selector .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $mat-text-color;
}

.mat-option.week-warn {
  color: $theme-color-warn;
}

.mat-button.mat-default,
.mat-raised-button.mat-default,
.mat-badge-content.mat-primary {
  background-color: $mat-button-default-background-color;
  color: $mat-button-default-text-color;
}

.mat-button.mat-black,
.mat-raised-button.mat-black,
.mat-badge-content.mat-black, {
  background-color: $mat-button-black-background-color;
  color: $mat-button-black-text-color;
}
